const ENV = {
        dev: {
            proxyTarget: "http://39.106.12.100:30171",
            generateFileTarget: "http://39.106.12.100:30171",
            homeUrl: "/sys/",

            // 本地cas需要代理
            casLoginProxy: "http://192.168.1.100:10100",
            casUrl: "/cas-req-head",
            casServerUrl: "http://101.200.232.170:6687",
            loginUrl: 'https://ecs.jtsww.com/login.action',

            cookieDomain: '', //用来设置token时，修改cookie的domain，正式环境的网站和系统在两个域名下，使用网站www登录的话，需要把cookie通道ebs下
        },
        prod: {
            proxyTarget: "http://39.105.107.230:30110",
            generateFileTarget: "http://39.105.107.230:30107",
            homeUrl: "https://ebs.jtsww.com/", //业务首页
            webUrl: 'https://ebs.jtsww.com/', //网站首页

            // 非本地环境直接访问ip，不走代理
            casLoginProxy: "http://39.105.107.230:30112",
            casUrl: "https://cassso.jtsww.com/cas",
            casServerUrl: "https://cassso.jtsww.com/cas",
            loginUrl: 'https://ecs.jtsww.com/login.action',

            cookieDomain: '.jtsww.com',
        },
        test: {
            proxyTarget: "http://10.10.0.155:30166",
            generateFileTarget: "http://10.10.0.155:30163",
            homeUrl: "/sys/", //业务首页
            webUrl: 'http://sandbox.jtsww.com:30171/',  //网站首页

            // 非本地环境直接访问ip，不走代理
            casLoginProxy: "http://10.10.0.155:30172",
            casUrl: "http://sandbox.jtsww.com:30174/cas",
            casServerUrl: "http://sandbox.jtsww.com:30174/cas",
            loginUrl: 'http://sandbox.jtsww.com:30171/cas/login',
            
            cookieDomain: '',
        },
    },
    ENV_MODE = ENV[process.env.MODE || "prod"];

export default ENV_MODE;
