<template>
    <header
        class="header"
        :class="{fixed: fixed}"
    >
        <div class="header-inner">
            <div class="logo-con">
                <img
                    class="logo header-com"
                    src="/config/logo.png"
                />

                <div class="name">
                    河北建投集团数字化招标采购平台

                    <span class="ipv6">
                        IPv6
                    </span>
                </div>
            </div>

            <div class="nav-con" id="box">
                <div class="nav-list">
                    <template v-for="(item, index) in $store.state.config.nav">

                        <a
                            v-if="item.isExternalLink"
                            :href="item.href"
                            :key="item.title"
                            target="_blank"
                            class="nav-item"
                        >{{item.title}}</a>

                        <nuxt-link
                            class="nav-item"
                            :key="index"
                            :to="item.href"
                            :class="{active: isActive(item)}"
                            @click="clickHandler($event, item.click)"
                            v-else
                        >
                            {{item.title}}
                        </nuxt-link>
                    </template>
                </div>
            </div>

            <div class="search-con">
                <search></search>
            </div>
        </div>

        <slot></slot>
    </header>
</template>

<script>
import Search from './components/search';

export default {
    components: {
        Search,
    },
    props: {
        theme: {
            type: String,
            default: '',
        },
        fixed: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        isActive(item) {
            var path = this.$route.path,
                href = item.href,
                activeReg = item.activeReg;

            if (href === path) {
                return true;
            }

            if (path === href) {
                return true;
            }

            // if (activeReg && activeReg.test(path)) {
            //     return true;
            // }

            return false;
        },
        clickHandler(e, cb) {
            if (cb) {
                e.preventDefault();

                var fun = new Function(cb);
                fun.call(this);
            }
        },
    },
    mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@css/var.scss';

.header {
    overflow: hidden;
    cursor: default;
    height: 74px;
    background: white;
    display: flex;
    align-items: center;
    padding: 0 60px;

    &.fixed {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10000;
        min-width: 1200px;
    }

    .header-inner {
        display: flex;
        flex: 1;
    }

    .logo-con {
        display: flex;
        align-items: center;

        img.logo {
            width: 174px;
            height: 42px;
        }

        .name {
            color: $primaryColor;
            font-size: 16px;
            font-weight: bold;
            position: relative;
            margin-left: 1.8em;
            white-space: nowrap;

            &:before {
                content: '';
                display: block;
                height: 100%;
                width: 1px;
                position: absolute;
                left: -0.9em;
                top: 0;
                background: $primaryColor;
            }
        }
    }

    .nav-con {
        flex: 1;
        display: flex;
        align-items: center;
        // min-width: 580px;

        .nav-list {
            font-size: 16px;
            overflow: hidden;
            margin-left: 100px;

            .nav-item {
                display: block;
                float: left;
                cursor: pointer;
                color: #575d6c;
                position: relative;
                // line-height: 1em;
                font-size: inherit;

                &:hover {
                    font-weight: bolder;
                    color: $primaryColor;
                }

                & + .nav-item {
                    margin-left: 4em;
                }

                &.active {
                    font-weight: bolder;
                    color: $primaryColor !important;
                }
            }
        }
    }

    .search-con {
        display: flex;
        align-items: center;
    }
}

@media only screen and (max-width: 1660px) {
    //xl

    .header {
        padding: 0 30px;

        .nav-con {
            .nav-list {
                margin-left: 60px;

                .nav-item {
                    & + .nav-item {
                        margin-left: 2em;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1450px) {
    //xl

    .header {
        padding: 0 30px;

        .nav-con {
            .nav-list {
                margin-left: 40px;

                .nav-item {
                    & + .nav-item {
                        margin-left: 1em;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1340px) {
    //xl

    .header {
        // padding: 0 20px;

        .nav-con {
            width: 520px;
            .nav-list {
                margin-left: 20px;

                .nav-item {
                    & + .nav-item {
                        margin-left: 1em;
                    }
                }
            }
        }
    }
}

.ipv6 {
    cursor: initial;
    color: #e6a23c;
    background-color: initial;
    width: 70px;
    height: 22px;
    border-radius: 11px;
    border: 1px solid #e6a23c;
    line-height: 22px;
    display: inline-block;
    text-align: center;
    margin-left: 25px;
    font-size: 14px;
}
</style>
