<template>
    <div class="search">
        <el-input
            class="search-input"
            v-model="value"
            :maxlength="20"
            placeholder="搜索"
        >

            <el-button
                type="primary"
                slot="append"
                icon="el-icon-search"
                @click="clickHandler"
            ></el-button>
        </el-input>
    </div>
</template>

<script>
export default {
    data() {
        return {
            value: '',
            questionVisible: false,

            historyList: [],
            suggestList: [],
        };
    },
    methods: {
        /**
         * 搜索
         */
        clickHandler() {
            // this.$get(
            //     `/search/log.json`,
            //     {
            //         title: this.value,
            //     },
            //     (data) => {
            var title = encodeURIComponent(this.value);

            //         if (/^\/search/.test(this.$route.path)) {
            //             window.location.assign(
            //                 `${this.$route.path}?title=${title}`
            //             );
            //         } else {
            window.location.assign(`/search?title=${title}`);
            //         }
            //     }
            // );
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
@import '@css/var.scss';

.search {
    .search-input {
        width: 260px;
        vertical-align: middle;

        ::v-deep {
            .el-input__inner {
                color: #999999;
                border-right: none;
                height: 34px;
                font-size: 12px;

                &:focus + .el-input-group__append {
                    border-color: $primaryColor;
                }
            }

            .el-input-group__append {
                background-color: transparent;
                border-color: transparent;
                color: #575d6c;
                font-size: 16px;
                padding: 0 16px;
                transition: 0.3s border-color;
                border: 1px solid #dcdfe6;
                border-left: none;
            }
        }
    }
}
</style>