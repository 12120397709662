import Vue from 'vue';

export const state = () => ({
    serverMoment: 0,
    clientMoment: 0,
    delta: 0,

    serverTime: 0,
    timeInterval: null,

})

export const mutations = {
    updateTime(state, n) {
        state.serverMoment = n;
        state.clientMoment = new Date().getTime();
        state.delta = state.serverMoment - state.clientMoment;
    },
}

export const getters = {
    time(state) {
        var currentTime = new Date().getTime();

        return (state.delta + currentTime);
    },
}

export const actions = {
    checkTime(state) {
        Vue.prototype.$get(`${this.state.api.sysUrl}/common/getnowtime.json`, data => {
            state.commit('updateTime', data);
        });

        state.commit('updateTime', new Date().getTime());
    },
};