<template>
    <footer class="footer">
        <div class="content">
            <div class="top">
                <div class="left">
                    <div class="info-item" v-for="(item, index) in info" :key="index">
                        <div class="title">
                            {{item.title}}
                        </div>
                        <div class="context">
                            <div 
                            v-for="(row, rowIndex) in item.context" :key="rowIndex"
                            class="item"
                            >
                                <template v-if="row.href">
                                    <a :href="row.href" target="_blank">
                                        {{row.text}}
                                    </a>
                                </template>

                                <template v-else>
                                    {{row}}
                                </template>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="right">
                    <div class="title">
                        关注我们
                    </div>

                    <div class="list">
                        <div
                            class="item"
                            v-for="(item, index) in qrcode"
                            :key="index"
                        >
                            <div class="qrcode">
                                <img
                                    height="86"
                                    width="86"
                                    :src="item.url"
                                    :alt="item.name"
                                >
                            </div>

                            <div class="name">
                                {{item.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bottom">
                <div class="copy-right">
                    <span class="item">
                        <a
                            :href="$store.state.config.baseInfo.companyNameUrl"
                            target="_blank"
                        >{{$store.state.config.baseInfo.companyName}}</a>

                        版权所有© 2015-{{ nowyear }}
                    </span>

                    <span class="item">

                        <a
                            :href="`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${recordCode}`"
                            target="_blank"
                        >
                            <img class="psb-icon" src="https://static-assets.sxlcdn.com/images/landing-sxl/gongan-beian.png">
                            {{$store.state.config.baseInfo.recordUrl}}
                        </a>

                    </span>

                    <span class="item">

                        <a
                            href="https://beian.miit.gov.cn/#/Integrated/index"
                            target="_blank"
                        >
                            {{$store.state.config.baseInfo.recordCode}}
                        </a>

                    </span>

                    <!-- <span
                        class="item"
                        v-if="$store.state.config.security"
                    >
                        <a
                            target="_blank"
                            :href="`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${$store.state.config.security.code}`"
                            style="margin: 0 1em;"
                        >
                            {{$store.state.config.security.text}}
                        </a>

                    </span> -->

                    <span class="item">
                        <cnzz></cnzz>
                    </span>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    data() {
        return {
            info: [],
            qrcode: [],
        };
    },
    computed: {
        recordCode() {
            var recordCode = this.$store.state.config.baseInfo.recordUrl || '',
                num = recordCode.replace(/[^\d]/g, '');

            return num;
        },
        nowyear() {
            return new Date().getFullYear();
        },
    },
    methods: {
        init() {
            var that = this;
            this.$nextTick(() => {
                this.info = [
                    {
                        title: '客户服务',
                        context: [
                            `电话：${that.$store.state.config.baseInfo.servePhone}`,
                            `邮箱：${that.$store.state.config.baseInfo.serveEmail}`,
                        ],
                    },
                    {
                        title: '营业地点',
                        context: [
                            `${that.$store.state.config.baseInfo.businessAddress}`,
                        ],
                    },
                    {
                        title: '工商执照',
                        context: [
                            {
                                text: `${that.$store.state.config.baseInfo.orgCode}`,
                                href: 'https://www.gsxt.gov.cn/corp-query-homepage.html',
                            },
                        ],
                    },
                    {
                        title: '客户投诉',
                        context: [
                            `邮箱：${that.$store.state.config.baseInfo.complainEmail}`,
                        ],
                    },
                    {
                        title: '营业时间',
                        context: [
                            `${that.$store.state.config.baseInfo.businessTime}`,
                        ],
                    },
                    {
                        title: '站点认证',
                        context: [
                            {
                                text: `${that.$store.state.config.baseInfo.siteCertification}`,
                                href: 'https://evwebverify.cfca.com.cn/WebVerify/webVerifyServlet?domain=www.jtsww.com',
                            },
                        ],
                    },
                ];

                this.qrcode = [
                    {
                        url: this.$store.state.config.baseInfo
                            .clientServiceQrCode,
                        name: '客户服务群',
                    },
                    {
                        url: this.$store.state.config.baseInfo.videoQrCode,
                        name: '微信视频号',
                    },
                    {
                        url: this.$store.state.config.baseInfo.publicQrCode,
                        name: '微信公众号',
                    },
                ];
            });
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style scoped lang="scss">
@import '@css/var.scss';

$textColor: #9b9ea0;

.footer {
    background: #202020;
    padding: 70px 0;
    color: white;

    a {
        color: $textColor;
    }

    .top {
        overflow: hidden;
        padding-bottom: 18px;
        display: flex;

        .left {
            display: flex;
            flex-wrap: wrap;
            width:70%;
            .info-item{
                width:33%;
                margin-bottom: 22px;
                padding-right: 60px;

                .title{
                    color: white;
                    margin-bottom: 16px;
                    line-height: 1em;
                }

                .context{
                    color: $textColor;

                    .item{
                        line-height: 1.5em;
                    }
                }
            }
        }

        .right {
            // flex: 1;
            width:30%;

            .title{
                font-size: 16px;
                margin-bottom: 1em;
            }

            .list{
                display: flex;
                .item{
                    // flex: 1;
                    width:33.3%;

                    .qrcode{
                        width: 86px;
                        height: 86px;
                        img{
                            width: 86px;
                        }
                    }

                    .name{
                        text-align: center;
                        color: $textColor;
                        line-height: 1em;
                        margin-top: 10px;
                    }

                     & + .item{
                        margin-left: 35px;
                    }
                }
            }
        }
    }

    .bottom {
        border-top: 1px solid #7d7d7d;
        padding-top: 48px;
        text-align: center;

        .copy-right {
            line-height: 30px;
            color: $textColor;
            overflow: hidden;

            a {
                color: $textColor;
            }

            .item {
                position: relative;

                & + .item {
                    margin-left: 4em;
                }
            }

            & + .copy-right {
                margin-top: 1em;
            }
        }
    }
}
</style>