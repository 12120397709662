import ENV_MODE from '../env/index.js';

export default {
  head: {
    title: "建投商务网-电子招标采购平台",
    meta: {
      description: "建投商务网-电子招标采购平台",
      keywords: "建投商务网,电子招标采购平台",
    },
    icon: "/config/favicon.ico",
  },
  env: ENV_MODE,
  plugins: {
    element: true,
    myComs: true,
    vuexStorage: false,
    jquery: true,
    sentry: false,
    cnzz: {
      id: "1275340054",
      show: "pic1",
    },
  },
  proxy: {
    "/bidding-staticdata": {
      target: ENV_MODE.generateFileTarget,
      changeOrigin: true,
    },
    "/bidding-": {
      target: ENV_MODE.proxyTarget,
      changeOrigin: true,
    },
    "/cas-req-head": {
      target: ENV_MODE.casServerUrl,
      changeOrigin: true,
      pathRewrite: {
        "^/cas-req-head": "/",
      },
    },
    "/cas": {
      target: ENV_MODE.casLoginProxy,
      changeOrigin: true,
    },
  },
};
