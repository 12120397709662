(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined'
        ? (module.exports = factory())
        : typeof define === 'function' && define.amd
        ? define()
        : (window.$_plat_config = factory());
})(this, function () {
    var config = {
        title: '建投商务网',

        pswEncodeMethod: 'MD5',  // SHA256/MD5
        pswEncodeAppendMethod: 'toUpperCase', //toUpperCase
        casLogin: true, // cas登录方式

        // logo图维护地址如下，直接修改即可
        // logo: './config/logo.png',
        // logoSmall: './config/logo-small.png',

        cnzz: {
            id: '1275340054',
            show: 'pic1',
        },
        api: {
            globalUrl: '',
            userUrl: '/bidding-user',
            expertUrl: '/bidding-expert',
            authUrl: '/bidding-auth',
            sysUrl: '/bidding-system',
            fileUrl: '/bidding-basesev-file',
            portalsiteUrl: "/bidding-portalsite",
            tfUrl: '/bidding-tenderfile', // 招标文件
            bidderUrl: '/bidding-bidder', // 投标人系统
            staticdataUrl: '/bidding-staticdata',
            payUrl: '/bidding-basesev-pay',
            walletUrl:'/bidding-basesev-wallet',
            casServeUrl: '/cas-req-head', // cas登录退出接口
        },
        sys: {
            authorization: 'Basic YmlkZGluZzpseXhfc2VjcmV0',
        },
        // 网站端首页
        webHome: "/",
        // 手机端首页
        wapHome: "/wap/",
        i18n: {
            'zh-CN': {
                // 倾向
                success: '成功',
                warning: '警告',
                error: '错误',
                info: '提示',

                // 通用文字
                edit: '编辑',
                del: '删除',
                detail: '详情',
                new: '添加',
                gdel: '批量删除',
                search: '查询',
                save: '保存',
                cancel: '取消',
                view: '查看',
                temporary_storage: '暂存',

                // 通用组件提示
                del_warning: '删除后无法撤销，确认删除操作？',
                click_to_upload: '点击上传',
                drag_file_here_or: '将文件拖到此处，或',

                // manage 运维系统
                edit_dict_item: '编辑字典项',
                add_child_item: '添加子项',
                add_child_menu: '添加子菜单',
                api_auth: '接口权限',
                btn_auth: '按钮权限',
                view_details: '查看详情',
                pass: '通过',
                retreat: '退回',
                approved: '审核通过',
                reviewed_and_returned: '审核退回',
                
                //招标文件系统
                copy_paste_chapter:'拷贝粘贴章节项',
                add_subordinate:'添加下级',
                build:'新建',
                remove_file:'删除文件',
                upload_bidding_document:'上传招标文件正文',
                adopt: '通过',
                return: '退回',
                examine: '审核',
                reset: '重置',
                edit_audit_info: '编辑审核信息',
                audit: '审核',
                view_audit_status: '查看审核状态',

                // user 用户管理子依赖
                your_org_admin_has_not_assigned_you_permission: '您的组织管理员尚未给您分配权限',
                set_role: '设置角色',
                reset_psw: '重置密码',

                // expert 专家子依赖
                stop_extract: '停止抽取',
                start_extract: '恢复抽取',
                extract: '抽取',
                extract_result: '抽取结果',
                print_extract_result: '打印抽取结果',
                supplemental_extract: '补充抽取',
                edit_tenderee_representative: '修改招标人代表',
                view_extract_applyment: '查看抽取申请',

                import_excel: '导入Excel',
                download_temp: '下载模板',
                bind_pro_type: '关联项目类型',

                // ca
                apply_for_new_ca: '办理新证书',
                apply_for_exrension: '申请延期',
                make_ca: '制作证书',
                pre_step: '上一步',
                next_step: '下一步',
                edit_apply_info: '修改申请信息',
                inject_data: '申请证书',
                download_seal_info: '下载章信息',
                mail_info:'邮寄信息',

                // finance
                confirmEntry: "确认入账",
                confirmRecharge: "确认充值",
                related_remittance_record: '关联汇款记录',
                recharge_now: '立即充值',
                confirm_withdrawal: '确认提现',
                immediate_payment:'立即支付',
                withdrawal:"提现",
                recharge:"充值",
                open_account:"开通账户",
                update_all_collection_records:'更新全部收款记录',
                view_unassociated_account_data:'查看未关联账户数据',
                update_user_flow:'更新用户流水',
                into:'进入',
                modify_payee:"修改收款人",
                make_visible:'设为可见',


                please_select_relation_info: "请选择需要关联的入账信息",
                tips_for_associated_deposit_and_recharge : '可关联进账进行余额充值，如关联保证金入账请进入"我的项目"选择相应标段进入流程操作。',
                balance_recharge:'确定使用该笔交易进行余额充值吗？关联后无法撤回入账。',
                no_entry_record:"由于您的专属账户中无进账记录，无法进行余额充值，请先确认账户汇款情况！",


                //投标系统
                participate:'我要参与',
                go_to_process:'进入流程',
                cancel_participation:'取消参与',
                raise_file_query:'提出质疑',
                submit:'提交',
                resubmit:'重新提交',
                withdraw:'撤回',
                view_receipt:'查看回执',
                download_file:'点此下载招标文件',
                deposit_payment:'缴纳保证金',
                apply_for_guarantee:'申请保函',
                related_remittance_record:'关联汇款记录',
                immediate_payment :'立即支付',
                order_payment:'下订单支付',
                add_complaint_record:'添加投诉记录',
                add_query:'提交质疑',
                edit_query:'编辑质疑',
                raise_objection:'提交异议',
                edit_objection:'编辑异议',
                view_detail:'查看详情',
                add_objection:'提出异议',
                view_tender_document:'查看投标文件',
                view_attachments:'查看附件'
                // 子系统1

                // 子系统2
            },
        },
    };

    return config;
});
