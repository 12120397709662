import {api} from '../static/config/index.js';

export const state = () => ({
    ...api,
})

export const mutations = {
    
}

export const getters = {
    
}
