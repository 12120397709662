import util from './util';
import { MessageBox } from 'element-ui';

const LOGIN_URL = '/';

function isNode(cb1, cb2) {
    if(typeof window === 'undefined') {
        cb1 && cb1();
    } else {
        cb2 && cb2();
    };
};

export default function(obj, settings, callback, context){
    var innerCallback = callback,
        switchObj;

    if (arguments.length === 2) {
        innerCallback = settings;
    }

    switchObj = {
        200: function() {
            // 成功
            innerCallback && innerCallback(obj.data, obj);
            return [obj.data, obj];
        },
        204: function() {
            // 成功，但无内容返回。OPTIONS 请求时返回
            innerCallback && innerCallback(obj.data, obj);
            return [obj.data, obj];
        },
        301: function() {
            // 永久移动。新地址输入到 Location 头中
            
            isNode(() => {
                console.warn(`即将redirect：${settings}`);

                var redirectto = data;

                context.redirect({
                    path: redirectto,
                });
            }, () => {
                MessageBox({
                    message: obj.msg,
                    type: 'error',
                    callback: () => {
                        window.location.assign(data);
                    }
                });
            });

            return false;
        },
        304: function() {
            // 未修改
            innerCallback && innerCallback(obj.data, obj);
            return [obj.data, obj];
        },
        400: function() {
            // 业务错误

            isNode(() => {
                console.error(settings);
            }, () => {
                MessageBox({
                    message: obj.msg,
                    type: 'error'
                });
            });

            return false;
        },
        401: function() {
            // （未授权） 请求要求身份验证。 对于需要登录的网页，服务器可能返回此响应

            isNode(() => {
                console.error(settings);
            }, () => {
                MessageBox({
                    message: obj.msg,
                    type: 'error',
                    callback: () => {
                        sessionStorage.clear();

                        window.location.assign(LOGIN_URL);
                    }
                });
            });

            context.removeToken();

            return false;
        },
        403: function() {
            // 由于权限原因，拒绝访问。

            isNode(() => {
                console.error(settings);
            }, () => {
                MessageBox({
                    message: obj.msg,
                    type: 'error',
                    callback: () => {
                        window.history.back();
                    }
                });
            });
            
            return false;
        },
        404: function() {
            // 资源未找到。

            isNode(() => {
                console.error(settings);
            }, () => {
                MessageBox({
                    message: obj.msg,
                    type: 'error',
                });
            });

            return false;
        },
        405: function() {
            // 请求方法被禁止。

            isNode(() => {
                console.error(settings);
            }, () => {
                MessageBox({
                    message: obj.msg,
                    type: 'error',
                });
            });

            return false;
        },
        408: function() {
            // 请求超时，如上传大文件超时

            isNode(() => {
                console.error(settings);
            }, () => {
                MessageBox({
                    message: obj.msg,
                    type: 'error',
                });
            });

            return false;
        },
        410: function() {
            // 接口已过期
            isNode(() => {
                console.error(settings);
            }, () => {
                MessageBox({
                    message: obj.msg,
                    type: 'error',
                });
            });

            return false;
        },
        500: function() {
            // 内部服务器错误
            isNode(() => {
                console.error(settings);
            }, () => {
                MessageBox({
                    message: obj.msg,
                    type: 'error',
                });
            });

            return false;
        },
        502: function() {
            // 网关错误
            isNode(() => {
                console.error(settings);
            }, () => {
                MessageBox({
                    message: obj.msg,
                    type: 'error',
                });
            });

            return false;
        },
    };

    return switchObj[obj.code]
        ? switchObj[obj.code]()
        : (function() {
            isNode(() => {
                console.error(settings);
            }, () => {
                MessageBox({
                    message: obj.msg,
                    type: 'error',
                });
            });

            util.throwError({
                settings,
                obj,
                msg: 'unexpeted ajaxResCheck code'
            });
        }());
}
