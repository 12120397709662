<template>
    <div class="right_nav">
        <!-- <div class="top_bar">调查问卷</div> -->
        <div
            class="bottom_bar"
            @mouseenter="show = true"
            @mouseleave="show = false"
        >
            <div
                class="consult"
                v-if="show"
            >
                <img :src="$store.state.config.baseInfo.clientServiceQrCode" />
                <div>微信扫码 立即咨询</div>
            </div>
            <img
                :src="require('@/static/images/home/kefu.png')"
                class="kefu"
            />
        </div>
        <el-backtop
            class="backtop-layout"
            target="#__nuxt"
            icon="el-icon-arrow-up"
        ></el-backtop>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            show: false,
        };
    },
    created() {},
    methods: {
        shh9() {
            dfdfdf;
        },
    },
    mounted() {},
    computed: {},
};
</script>
<style scoped lang="scss">
@import '@css/var.scss';

.right_nav {
    position: fixed;
    right: 20px;
    bottom: 98px;
    z-index: 99;

    .top_bar {
        width: 48px;
        height: 135px;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        text-align: center;
        line-height: 48px;
        letter-spacing: 8px;
        color: #ffffff;
        background: $primaryColor;
        writing-mode: vertical-lr;
        border-radius: 2px;
        cursor: pointer;
    }

    .bottom_bar {
        width: 48px;
        height: 48px;
        margin-top: 8px;
        background: $primaryColor;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        cursor: pointer;
        position: relative;
    }

    .backtop-layout {
        right: 20px !important;
        width: 48px;
        height: 48px;
        cursor: pointer;

        box-shadow: 0px 0px 7px 1px rgba(179, 179, 179, 0.2);
        border-radius: 2px;
    }

    .consult {
        width: 169px;
        height: 186px;
        position: absolute;
        right: 73px;
        bottom: -55px;
        background: #fff;
        color: #575d6c;
        font-size: 14px;
        line-height: 20px;
        padding: 22px;
        border: solid 1px #e6edf5;

        img {
            width: 117px;
            height: 116px;
        }
    }

    ::v-deep {
        .el-icon-caret-top:before {
            content: '';
            color: #575d6c;
        }
    }
}
</style>
