import Vue from 'vue';

const TOKEN_NAME = 'bidding-auth',
    IS_CAS_NAME = 'is-cas',
    IS_CAS_VALUE = '1';

export default function({ app, isDev }) {
    const EXTRA_SETTING = {
        domain: app.$config.cookieDomain,
        path: '/',
    };

    function getToken() {
        return app.$cookies.get(TOKEN_NAME);
    }

    function setToken(token) {
        return app.$cookies.set(TOKEN_NAME, `bearer ${token}`, EXTRA_SETTING);
    }

    function removeToken() {
        app.$cookies.remove(TOKEN_NAME, EXTRA_SETTING);
        app.$cookies.remove(IS_CAS_NAME, EXTRA_SETTING);

        return true;
    }

    // 将当前状态设为cas登录
    function setIsCas() {
        return app.$cookies.set(IS_CAS_NAME, IS_CAS_VALUE, EXTRA_SETTING);
    }

    function getIsCas() {
        console.log(IS_CAS_NAME)

        var isCas = app.$cookies.get(IS_CAS_NAME);

        console.log(isCas);

        console.log(isCas === IS_CAS_VALUE);

        console.log("////")

        return isCas == IS_CAS_VALUE;
    }

    Vue.prototype.getToken = getToken;
    Vue.prototype.setToken = setToken;
    Vue.prototype.removeToken = removeToken;
    Vue.prototype.setIsCas = setIsCas;
    Vue.prototype.getIsCas = getIsCas;

    app.getToken = getToken;
    app.setToken = setToken;
    app.removeToken = removeToken;
    app.setIsCas = setIsCas;
    app.getIsCas = getIsCas;
}