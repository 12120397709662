<template>
    <div class="layout-default">
        <my-header></my-header>

        <div class="page-body">
            <slot>
                <nuxt />
            </slot>
        </div>

        <right-bar></right-bar>

        <my-footer></my-footer>
    </div>
</template>

<script>
import rightBar from '../pages/index/components/right-bar.vue';
// import IS_MODEL from '@mixins/is-model';
export default {
    // mixins:[IS_MODEL],
    name: 'layout-default',
    components: { rightBar },
    data() {
        return {};
    },
    methods: {
       
    },
    mounted() {
        // this.$store.dispatch("server/checkTime");
    },
};
</script>

<style scoped lang="scss">
.layout-default {
    background-color: #f5f7fd;
    width: 100%;
}

.page-body {
}
</style>
