import Vue from 'vue';
import storage from '@js/storage';

export const state = () => ({
    data: {},

    dialogLoginVisible: false,
    loginCallback: null,
    companyTypecode: ''
});

export const mutations = {
    updateData(state, n) {
        state.data = n;
        storage.setSession({
            userinfo: n,
        });
    },
    updateDialogLoginVisible(state, n) {
        state.dialogLoginVisible = n;
    },
    setLoginCallback(state, n) {
        state.loginCallback = n;
    },
};

export const getters = {
    getData(state) {
        var data = {};
        if(process.browser) {
            let stateData = state.data || {},
                keys = Object.keys(stateData);

            if (keys.length) {
                data = state.data;
            } else {
                data = storage.getSession('userinfo')
            }
        }
        return data;
    },
    // 用户昵称
    getNickname(state, getters) {
        return getters.getData.mobile;
    },
    //用户头像
    getHeadportraitpath(state, getters) {
        return getters.getData.headportraitpath;
    },
    getType(state, getters) {
        // P: 普通用户

        return getters.getData.type;
    },
    getDialogLoginVisible(state, getters) {
        return state.dialogLoginVisible;
    },
};

export const actions = {
    /**
     * 请求用户身份信息，仅在有SERIAL_CODE时请求
     * @param {Object} state state实例
     * @param {Object} obj 参数
     * @returns 
     */
    queryUserInfo(state, obj = {requery: false}) {
        const SERIAL_CODE = this.$cookies.get('SERIAL_CODE');

        if (!SERIAL_CODE) return;

        var query = () => {
            Vue.prototype.$get(`${this.state.api.webUrl}/userlogin/userinfo.json`, data => {
                state.commit('updateData', data);

                // 如果客户端
                if (window.electronApi) {
                    //data为用户信息
                    window.electronApi.dispatch('login', data);
                }
            });
        };

        if (obj.requery) {
            query();
        } else {
            if (state.getters.getNickname) return;

            query();
        }
    },
    /**
     * 验证码登录
     * @param {Object}} state state实例
     * @param {Object} obj 传入的参数，包含data，success
     */
    smsloginHandler(state, {data, success, complete, error}) {
        Vue.prototype.$ajax({
            type: 'post',
            url: `${this.state.api.authUrl}/login`,
            data: {
                ...data,
            },
            callback: data => {
                state.commit('updateData', data);

                Vue.prototype.setToken(data.accessToken);
                
                success.call(this, data);
            },
            complete() {
                complete && complete.call(this)
            },
            error() {
                error && error.call(this);
            }
        });
    },

    /**
     * 手机号 验证码登录
     * @param {Object}} state state实例
     * @param {Object} obj 传入的参数，包含data，success
     */
    phoneloginHandler(state, {data, success, complete, error}) {
        Vue.prototype.$ajax({
            type: 'post',
            url: `${this.state.api.authUrl}/phone-login`,
            data: {
                ...data,
            },
            callback: data => {
                state.commit('updateData', data);

                Vue.prototype.setToken(data.accessToken);
                
                success.call(this, data);
            },
            complete() {
                complete && complete.call(this)
            },
            error() {
                error && error.call(this);
            }
        });
    },

    /**
     * 手机号 验证码登录
     * @param {Object}} state state实例
     * @param {Object} obj 传入的参数，包含data，success
     */
     caLoginHandler(state, {data, success, complete, error}) {
        Vue.prototype.$ajax({
            type: 'post',
            url: `${this.state.api.authUrl}/ca-login`,
            data: {
                ...data,
            },
            callback: data => {
                state.commit('updateData', data);

                Vue.prototype.setToken(data.accessToken);
                
                success.call(this, data);
            },
            complete() {
                complete && complete.call(this)
            },
            error() {
                error && error.call(this);
            }
        });
    },

    
    /**
     * 退出登录，成功后清空用户信息缓存
     * @param {Object} state state实例
     * @returns 
     */
    logoutHandler(state) {
        Vue.prototype.$post(`${this.state.api.authUrl}/logout`, () => {
            state.commit('updateData', {});

            Vue.prototype.removeToken();

            window.location.reload();
        });
    },
    nuxtServerInit({commit}, {req}) {
        // console.log(req.headers.cookie);
    },
    showLogin({commit}, cb) {
        if (cb) {
            commit('setLoginCallback', cb);
        } else {
            commit('setLoginCallback', null);
        }

        commit('updateDialogLoginVisible', true);
    },
    loginCallbackDispatch({state, commit}) {
        var callback = state.loginCallback;

        if (callback && typeof callback === 'function') {
            callback();

            commit('setLoginCallback', null);
        }
    },
    checkIsLogin(state, callback) {
        const SERIAL_CODE = this.$cookies.get('SERIAL_CODE');	

        if (SERIAL_CODE) {
            // 已登录

            callback && callback();
        } else {
            // 未登录

            state.dispatch('showLogin', () => {
                callback && callback();
            });
        }
    },
    // 跳转到系统
    jumpToSys(state) {
        new Chain().link(next => {
            // 先获取用户信息，备用，后续拆分系统时，使用companyTypecode跳转至不同系统
            Vue.prototype.$get(`${this.state.api.userUrl}/login-info/user`, data => {
                console.log(data.user.companyTypecode);
                this.companyTypecode = data.user.companyTypecode

                next();
            });
        }).link(() => {
            // 获取用户菜单，并取首个，作为跳转后的地址
            Vue.prototype.$get(`${this.state.api.userUrl}/login-info/menu`, data => {
                function getFirst(arr) {
                    let first = arr[0];

                    if (first.children) {
                        return getFirst(first.children);
                    }

                    return first;
                }

                console.log(data);
                if(data.length == 0){
                    showMsgBox('您的组织管理员尚未给您分配权限','error',(action)=>{
                        if(action === 'confirm'){
                            window.location.replace(
                                `${this.$config.webUrl}`
                            );
                        }
                    })
                    return
                }else{

                    var firstUrl = getFirst(data).menuUrl;

                    if (firstUrl === '/user/perfectInfo') {
                        window.location.replace(
                            `${this.$config.homeUrl}#${firstUrl}`
                        );
                    }else if(firstUrl === '/user/company/editInfo'){
                        // 判断第一个菜单是否是完善信息页面
                        window.location.replace(
                            `${this.$config.homeUrl}#${firstUrl}`
                        );
                    } else if (['A', 'T','S'].includes(this.companyTypecode)) {
                        window.location.replace(
                            `${this.$config.homeUrl}#/tenderee/myWorkbench/index`
                        );
                    } else {
                        window.location.replace(
                            `${this.$config.homeUrl}#${firstUrl}`
                        );
                    }
                    // Vue.prototype.$get(`${this.state.api.userUrl}/certificateinfo/isCertOperate`, (data,res) => {
                        // switch (data) {
                        //     // case 400:   // 未绑定锁
                        //     //     if(state.user.companyTypecode === 'B'){
                        //     //         ShowMsgBox('未绑定CA锁，是否跳转到"证书绑定"页面进行绑定？','warning',(action)=>{
                        //     //             if(action === 'confirm') {
                        //     //                 store.commit('popBread');
                        //     //                 router.push('/ca/manage/bind/list');
                        //     //             } 
                        //     //         })
                        //     //     }
                        //     //     break;
                        //     // case 300:  //如果是旧锁，则进入换发流程
                        //     //     ShowMsgBox('您的数字证书需要更新升级，请插入数字证书后点击确定按钮！','warning',(action)=>{
                        //     //         if(action === 'confirm') {
                        //     //             router.push("/ca/user/apply/update");
                        //     //         } 
                        //     //     })
                        //     //     break;
                        //     case 410:  //已绑定CFCA锁，但加密证书公钥为空，请联系客服解绑后重新绑锁
                        //         ShowMsgBox(res.msg,'warning')
                        //         break;
                        //     case 420:  //已绑定河北CA锁，但加密证书公钥为空，请联系客服解绑后重新绑锁
                        //         ShowMsgBox(res.msg,'warning')
                        //         break;
                        //     // case 310:   // 该锁有尚未解密的投标文件，暂不能换发！
                        //     //     break;
                        //     // case 200:   // 已绑定新锁，可以操作 / 绑定的是河北CA锁
                        //     //     break;
                        //     }
                    // });

                }
            });
        }).run();
    },
};