export const state = () => ({
  config: {
    nav: [
      {
        title: '首页',
        href: '/',
        activeReg: /(^\/search*)|(^\/$)/,
      },
      {
        title: '新闻通知',
        href: '/news',
        activeReg: /(^\/news)|(^\/$)/,
      },
      {
        title: '下载专区',
        href: '/download',
        activeReg: /(^\/download)|(^\/$)/,
      },
      {
        title: '电子商城',
        href: 'https://www.jtsww.shop/',
        activeReg: /(^\/shop)|(^\/$)/,
        isExternalLink: true, //是否是外部链接
      },
      {
        title: '评标系统',
        href: 'https://ebs.jtsww.com/#/bideva/login',
        activeReg: /(^\/expert)|(^\/$)/,
        isExternalLink: true, //是否是外部链接
      },
      {
        title: '竞价大厅',
        href: '/auction',
        activeReg: /(^\/auction)|(^\/$)/,
      },
    ],
    baseInfo: {
      // tel: '0311-xxxxxxx',
      // email: 'service@jtsww.com',
      // workTime: '法定工作日 8:30-11:30 14:30-17:30',
      // zhizhao: '91130000567397459Y',
      // tousuEmail: 'complaint@jtsww.com',
      // address: '050051 河北省石家庄市桥西区裕华西路9号裕园广场A座3楼',
      // record: 'xxxxxxxxxx',
      // company: '河北建设投资集团有限责任公司',
      // copyright: '版权所有© 2015-2022',
      // security: {
      //   text: '冀公网安备13010402001349号|冀ICP备15007930号-1',
      //   code: '13010402001349',
      // },
    },
    friendshiplink: {},
  },
});

export const mutations = {
  setBaseInfo(state, val) {
    state.config.baseInfo = val;
  },
  setLinks(state, val) {
    state.config.friendshiplink = val;
  },
};
